import { Amplify, Auth } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as router from 'react-router-dom'
import './index.css'
import './assets/scss/theme.scss'
import './assets/scss/preloader.scss'
import { Home } from './page/home'
import reportWebVitals from './reportWebVitals'
import { Posts } from './page/posts'
import { Media } from './page/media'
import { FlashCards } from './page/flash-cards'
import { Courses } from './page/courses'
import { Tags } from './page/tags'
import { Lessons } from './page/lessons/lessons'

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_h96zd2MwH',
    userPoolWebClientId: '6l56d4upgfurh5ntcmh05r3ogr',
    mandatorySignIn: false,
  },
})

Auth.configure()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement,
)

root.render(
  <React.StrictMode>
    <router.BrowserRouter>
      <router.Routes>
        <router.Route path="/" element={<Home />} />
        <router.Route path="/posts" element={<Posts />} />
        <router.Route path="/flash-cards" element={<FlashCards />} />
        <router.Route path="/media" element={<Media />} />
        <router.Route path="/courses" element={<Courses />} />
        <router.Route path="/lessons" element={<Lessons />} />
        <router.Route path="/tags" element={<Tags />} />
      </router.Routes>
    </router.BrowserRouter>
  </React.StrictMode>,
)

reportWebVitals()
