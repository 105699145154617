import { Lesson } from '@chrisdunne-com/chrisdunne-types'
import React, { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { Editor } from '../../components/rte/Editor'
import { createSlugFromTitle } from '../../utils/resources'
import { putData } from '../../utils/server'

export default function AddLessonModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_lesson: Function
  lesson?: Lesson
}) {
  const [editorValue, setEditorValue] = useState(props.lesson?.content ?? '')

  function handleLessonSubmit(event: any) {
    event.preventDefault()

    const data: Lesson = {
      course: event.target.elements.course.value,
      slug: createSlugFromTitle(event.target.elements.title.value),
      title: event.target.elements.title.value,
      chapter: event.target.elements.chapter.value,
      description: event.target.elements.description.value,
      content: editorValue,
      lastUpdated: new Date(),
      publishDate: props.lesson?.publishDate ?? new Date(),
    }

    putData('/lesson', data).then(
      () => {
        props.put_lesson(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function editor_updated(value: string) {
    setEditorValue(value)
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Lesson
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleLessonSubmit}>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Course slug:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="course"
                name="course"
                required
                defaultValue={props.lesson?.course}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.lesson?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="chapter" className="col-sm-3 col-form-label">
              Chapter:
            </Label>
            <Col sm={9}>
              <Input
                type="number"
                className="form-control"
                id="chapter"
                name="chapter"
                required
                defaultValue={props.lesson?.chapter}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="description" className="col-sm-3 col-form-label">
              Description:
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                className="form-control"
                id="description"
                name="description"
                maxLength={500}
                rows={5}
                required
                defaultValue={props.lesson?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="preview" className="col-sm-3 col-form-label">
              Preview:
            </Label>
            <Col sm={9}>
              <Editor
                onChange={editor_updated}
                defaultValue={props.lesson?.content ?? ''}
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
