import React from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { Tag } from '@chrisdunne-com/chrisdunne-types'
import { putData } from '../../utils/server'

export default function AddTagModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_tag: Function
  tag?: Tag
}) {
  function handleSubmit(event: any) {
    event.preventDefault()

    const data: Tag = {
      name: event.target.elements.name.value,
    }

    putData('/tag', data).then(
      () => {
        props.put_tag(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Tag
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                defaultValue={props.tag?.name}
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
